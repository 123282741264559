export type TextScheme = {
  title: string;
  placeholder: string;
  lastProjectsTitle: string;
  entityName: string;
  otherProjectTitle: string;
  otherProjectDescription: string;
  sameProjectTitle: string;
};
export type EntityType = 'project' | 'referat' | 'course-work' | 'text';
export const getEntityText = (entity: EntityType = 'project'): TextScheme => {
  if (entity === 'referat') {
    return {
      title: 'Сгенерируй реферат<br> с помощью нейросети',
      placeholder: 'Введите тему реферата',
      lastProjectsTitle: 'Последние рефераты',
      entityName: 'реферат',
      otherProjectTitle: 'Нужен другой реферат?',
      otherProjectDescription: 'Создай реферат на любую тему за 60 секунд',
      sameProjectTitle: 'Похожие рефераты',
    };
  }
  if (entity === 'course-work') {
    return {
      title: 'Создай курсовой проект <br> с помощью нейросети',
      placeholder: 'Введите тему курсовой',
      lastProjectsTitle: 'Последние курсовые',
      entityName: 'проект',
      otherProjectTitle: 'Нужен другой проект?',
      otherProjectDescription: 'Создай проект на любую тему за 60 секунд',
      sameProjectTitle: 'Похожие проекты',
    };
  }
  if (entity === 'text') {
    return {
      title: 'Создай уникальный текст на 20 страниц <br> с помощью нейросети',
      placeholder: 'Введите тему текста',
      lastProjectsTitle: 'Последние тексты',
      entityName: 'текст',
      otherProjectTitle: 'Нужен другой текст?',
      otherProjectDescription: 'Создай текст на любую тему за 60 секунд',
      sameProjectTitle: 'Похожие тексты',
    };
  }
  return {
    title: 'Создай индивидуальный проект <br> с помощью нейросети',
    placeholder: 'Введите тему проекта',
    lastProjectsTitle: 'Последние проекты',
    entityName: 'проект',
    otherProjectTitle: 'Нужен другой проект?',
    otherProjectDescription: 'Создай проект на любую тему за 60 секунд',
    sameProjectTitle: 'Похожие проекты',
  };
};
